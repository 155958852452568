import { ReactNode } from 'react';

type IMainProps = {
  meta: ReactNode;
  children: ReactNode;
};

const Main = (props: IMainProps) => {
  return (
    <>
      <div className="bg-white">
        <main className="px-4 mx-auto w-full max-w-7xl sm:px-6 lg:px-8">
          <div className="shrink-0 pt-16">
            <img
              className="mx-auto w-auto h-16"
              src="/timeghost.png"
              alt="timeghost.io"
            />
          </div>
          <div className="py-16 mx-auto max-w-xl sm:py-24">
            {props.children}
          </div>
        </main>
        <footer className="px-4 mx-auto w-full max-w-7xl sm:px-6 lg:px-8">
          <div className="py-12 text-center border-t border-gray-200 md:flex md:justify-between">
            <p className="text-base text-gray-400">
              &copy; timeghost.io, All rights reserved.
            </p>
            <div className="flex justify-center mt-6 space-x-8 md:mt-0"></div>
          </div>
        </footer>
      </div>
    </>
  );
};

export { Main };
