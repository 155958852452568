import { useEffect } from 'react';

import confetti from 'canvas-confetti';
import { useTranslation } from 'next-i18next';

const Success = () => {
  const { t } = useTranslation('common');
  useEffect(() => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
    setTimeout(() => {
      window.location.href = `${
        `${process.env.NEXT_PUBLIC_API_URL}`.indexOf('dev') > -1
          ? 'https://web-dev.timeghost.io'
          : 'https://web.timeghost.io'
      }`;
    }, 5000);
  }, []);

  return (
    <div className="text-center">
      <p className="mt-2 text-lg text-gray-900">
        {t('sucess_message')}{' '}
        <a
          href={`${
            `${process.env.NEXT_PUBLIC_API_URL}`.indexOf('dev') > -1
              ? 'https://web-dev.timeghost.io'
              : 'https://web.timeghost.io'
          }`}
        >
          {t('here')}
        </a>
        .
      </p>
    </div>
  );
};

export { Success };
