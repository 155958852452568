import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

import microsoftPic from '../../public/microsoft.png';
import { Button } from './Button';

const Login = () => {
  const { instance } = useMsal();
  const { t } = useTranslation('common');
  return (
    <div className="text-center">
      <p className="mt-2 text-lg text-gray-900">{t('first_login')}</p>
      <p className="mt-2">
        <Button
          type="button"
          onClick={() => {
            instance.loginPopup();
          }}
          label={t('microsoft_login')}
          icon={
            <Image
              alt="MicrosoftLogo"
              aria-hidden="true"
              src={microsoftPic}
              width="16"
              height="16"
            ></Image>
          }
        ></Button>
      </p>
    </div>
  );
};

export { Login };
