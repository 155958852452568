import { useState, useEffect } from 'react';

import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { AssignLicense } from './AssignLicense';
import { Login } from './Login';

const Stepper = () => {
  const { accounts, inProgress } = useMsal();
  const { t } = useTranslation('common');
  const { query } = useRouter();
  const [isLoading, setLoading] = useState(false);
  const checkToken = () => {
    setLoading(true);
    fetch(`/api/valid`, {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        marketplaceToken: query.token,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.workspaceId) {
          window.location.href = `${
            `${process.env.NEXT_PUBLIC_API_URL}`.indexOf('dev') > -1
              ? `https://web-dev.timeghost.io/settings/workspace/team?workspaceid=${data.workspaceId}`
              : `https://web.timeghost.io/settings/workspace/team?workspaceid=${data.workspaceId}`
          }`;
        }
        setLoading(false);
      });
  };
  useEffect(() => {
    if (!query.token) return;
    checkToken();
  }, [query.token]);
  if (isLoading) return <p>{t('loading')}</p>;
  if (accounts.length > 0) {
    return <AssignLicense></AssignLicense>;
  }
  if (inProgress === 'login') {
    return <span>{t('login_progress')}</span>;
  }
  return <Login />;
};

export { Stepper };
