import { MouseEventHandler } from 'react';

export function Button(props: {
  type: 'button' | 'submit';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  icon?: any;
  label: string;
  disabled?: boolean;
}) {
  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      type={props.type}
      className="inline-flex items-center px-5 h-8 text-white bg-microsoft hover:bg-microsoft-hover focus:bg-microsoft-focus rounded-sm border border-transparent shadow-lg cursor-pointer f"
    >
      {props.icon && <div className="mr-2"> {props.icon}</div>}

      <span className="font-medium">{props.label}</span>
    </button>
  );
}
