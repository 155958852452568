import { useEffect, useState } from 'react';

import { useAccount, useMsal } from '@azure/msal-react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { Button } from './Button';
import { Success } from './Success';
import Workspaces from './Workspaces';

const AssignLicense = () => {
  const { instance, accounts } = useMsal();
  const { query } = useRouter();
  const account = useAccount(accounts[0] || {});
  const [isLoading, setLoading] = useState(false);
  const [microsoftToken, setMicrosoftToken] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [success, setSucess] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const { t } = useTranslation('common');
  const [selectedWorkspace, setSelectedWorkspace] = useState<
    { id: string; name: string } | undefined
  >();
  const callTimeghostApi = (token: string) => {
    fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/get/workspaces`, {
      method: 'get',
      headers: new Headers({
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        const newWorkspacesList = data.filter(
          (x: any) =>
            x.subscription.status === 'trial' ||
            x.subscription.status === 'deleted'
        );
        setWorkspaces(newWorkspacesList);
        if (newWorkspacesList.length > 0)
          setSelectedWorkspace(newWorkspacesList[0]);

        setLoading(false);
      });
  };
  useEffect(() => {
    if (account) {
      setLoading(true);
      instance
        .acquireTokenSilent({
          scopes: [`${process.env.NEXT_PUBLIC_Scope_Url}`],
          account,
        })
        .then((response) => {
          if (response) {
            setMicrosoftToken(response.accessToken);
            callTimeghostApi(response.accessToken);
          }
        });
    }
  }, [account, instance]);

  const assignLicence = () => {
    if (!selectedWorkspace) return;
    setLoading(true);
    setErrorMessage(false);
    fetch(`/api/assign`, {
      method: 'post',
      headers: new Headers({
        authorization: `bearer ${microsoftToken}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        workspaceId: selectedWorkspace.id,
        marketplaceToken: query.token,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusText != null) {
          setErrorMessage(true);
          setLoading(false);
        } else {
          setSucess(true);
        }
        setLoading(false);
      });
  };
  if (success) return <Success></Success>;
  if (!query.token)
    return <p className="text-center text-red-600">{t('no_query_token')}</p>;
  if (isLoading) return <p>{t('loading')}</p>;
  if (workspaces.length === 0)
    return (
      <div className="text-center">
        {t('no_workspaces')}{' '}
        <a href="#" target={'_blank'}>
          {t('help')}
        </a>
      </div>
    );
  return (
    <>
      <Workspaces
        onChange={(a: { id: string; name: string } | undefined) => {
          setSelectedWorkspace(a);
        }}
        data={workspaces}
      ></Workspaces>
      {errorMessage && (
        <p className="text-sm text-center text-red-600">
          {t('error_message')}{' '}
          <a href="mailto:support@timeghost.io">{t('support')}</a>.
        </p>
      )}
      <div className="mt-2 text-right">
        <Button
          type="button"
          label={t('assign')}
          disabled={isLoading}
          onClick={() => {
            assignLicence();
          }}
        ></Button>
      </div>

      <button onClick={() => instance.logout()}>{t('switch-user')}</button>
    </>
  );
};

export { AssignLicense };
