import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { Stepper } from '@/components/Stepper';
import { Meta } from '@/layout/Meta';
import { Main } from '@/templates/Main';

const Index = () => {
  const { t } = useTranslation('common');
  return (
    <Main meta={<Meta title="timeghost.io" description="timeghost.io" />}>
      <div className="text-center">
        <p className="text-sm font-semibold tracking-wide text-microsoft uppercase">
          {t('finish')}
        </p>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
          {t('thanks')}
        </h1>
        <p className="mt-2 text-lg text-gray-500">{t('assign_license')}</p>
      </div>
      <div className="mt-12">
        <Stepper></Stepper>
      </div>
    </Main>
  );
};
export async function getStaticProps({ locale }: any) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      // Will be passed to the page component as props
    },
  };
}
export default Index;
