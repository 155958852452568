import { Fragment, useState } from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { SelectorIcon, CheckIcon } from '@heroicons/react/outline';
import { useTranslation } from 'next-i18next';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export default function Workspaces(props: {
  data: { id: string; name: string }[];
  onChange: (value: { id: string; name: string } | undefined) => void;
}) {
  const [selected2, setSelected] = useState(props.data[0]);
  const { t } = useTranslation('common');
  return (
    <>
      <Listbox
        value={selected2}
        onChange={(a) => {
          setSelected(a);
          props.onChange(a);
        }}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              {t('workspaces_in_status_trial')}
            </Listbox.Label>
            <div className="relative mt-1">
              <Listbox.Button className="relative py-2 pr-10 pl-3 w-full text-left bg-white rounded-md border border-gray-300 focus:border-microsoft-focus focus:outline-none focus:ring-1 focus:ring-microsoft-focus shadow-sm cursor-default sm:text-sm">
                <span className="block truncate">{selected2?.name}</span>
                <span className="flex absolute inset-y-0 right-0 items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="overflow-auto absolute z-10 py-1 mt-1 w-full max-h-60 text-base bg-white rounded-md focus:outline-none ring-1 ring-black shadow-lg sm:text-sm ring-opacity/5">
                  {props.data.map((person) => (
                    <Listbox.Option
                      key={person.id}
                      className={({ active }) =>
                        classNames(
                          active
                            ? 'text-white bg-microsoft-hover'
                            : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-8 pr-4'
                        )
                      }
                      value={person}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {person.name}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-microsoft',
                                'absolute inset-y-0 left-0 flex items-center pl-1.5'
                              )}
                            >
                              <CheckIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  );
}
